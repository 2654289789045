<template>
  <div
    class="w-full h-full py-4 bg-white flex flex-col items-center justify-center space-y-10"
  >
    <empty-state :message="'Empty state message'" :main_icon="'bot1'" />
    <empty-state :message="'Another empty state message'" :main_icon="'bot2'" />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
}
</script>
